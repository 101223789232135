import React, { useEffect, useRef, useState } from 'react';
import '../styles/ComparisonSlider.css';

const ComparisonSlider = ({ img1, img2, bg1color, bg2color, autoSlide = false, onSlideEnd  }) => {
  const [position, setPosition] = useState(100);
  const [sliderToggle, setSliderToggle] = useState(false);
  const sliderRef = useRef(null);

  /*
  const startDrag = (e) => {
    e.preventDefault();
    setIsDragging(true);
    const position = e.touches ? e.touches[0].clientX : e.clientX;
    updatePosition(position);
    sliderRef.current.classList.add('dragging');
  };

  const stopDrag = () => {
    setIsDragging(false);
    sliderRef.current.classList.remove('dragging');
  };

  const updatePosition = (positionX) => {
    if (!isDragging) return;
    const slider = sliderRef.current;
    const rect = slider.getBoundingClientRect();
    const newPosition = ((positionX - rect.left) / rect.width) * 100;
    setPosition(Math.max(0, Math.min(100, newPosition)));
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      e.preventDefault();
      const position = e.touches ? e.touches[0].clientX : e.clientX;
      updatePosition(position);
    };
    const handleMouseUp = () => stopDrag();

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchmove', handleMouseMove);
      window.addEventListener('touchend', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [isDragging]);
  */

  // 자동 슬라이드
  useEffect(() => {
    if (!autoSlide) return;

    let slideDirection = -100;
    const interval = setInterval(() => {
      setPosition((prevPosition) => {
        let nextPosition = prevPosition + slideDirection;
        if (nextPosition <= 0) {
          setSliderToggle(!sliderToggle)
          nextPosition = 100; // 우측 끝에서 다시 시작
          setTimeout(() => {
            onSlideEnd();
          }, 0);
        }
        return nextPosition;
      });
    }, 2500);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSlide, onSlideEnd]);


  return (
    <div className="ComparisonSlider">
      <div className="relative-box">
      <button 
        disabled 
        className='button-original' 
        style={{ display: sliderToggle ? 'none' : 'block' }}>변환 전</button>
      <button 
        disabled 
        className='button-done'
        style={{ display: !sliderToggle ? 'none' : 'block' }}>AI 변환 후</button>
      </div>
      <div ref={sliderRef} className="comparison-slider" /*onMouseDown={startDrag} onTouchStart={startDrag} onTouchEnd={stopDrag}*/>
        <figure style={{ backgroundColor: bg1color }}>
          <img src={img1} alt="Before" />
        </figure>
        <figure style={{ backgroundColor: bg2color, clipPath: `inset(0 ${100 - position}% 0 0)` }}>
          <img src={img2} alt="After" />
        </figure>
        {/* <div className="landingslider" style={{ left: `${position}%` }}>
          <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
            <path d="M1,14.9l7.8-7.6v4.2h12.3V7.3l7.9,7.6l-7.9,7.7v-4.2H8.8v4.2L1,14.9z" fill="black" />
          </svg>
        </div> */}
      </div>
    </div>
  );
};

export default ComparisonSlider;
